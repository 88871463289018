
import { Route, Routes } from 'react-router-dom';
import HomePage from './components/HomePage';
import CreateChatbot from './components/CreateChatbot';
import ViewChatbots from './components/ViewChatbots';
// import ChatbotContent from './components/ChatbotContent';
import ModifyChatbot from './components/ModifyChatbot';
import ChatWithChatbot from './components/ChatWithChatbot';
import LoginPage from './components/LoginPage'; // Import the new login page
import PrivateRoute from './components/PrivateRoute'; // Private route component
import './App.css'; // Import the global styles

function App() {
  return (
    <Routes>
      {/* Public Route - Login */}
      <Route path="/login" element={<LoginPage />} />

      {/* Private Route - Only accessible if logged in */}
      <Route
        path="/"
        element={
          <PrivateRoute>
            <HomePage />
          </PrivateRoute>
        }
      />

      {/* Other Routes (protected by default) */}
      <Route
        path="/create-chatbot"
        element={
          <PrivateRoute>
            <CreateChatbot />
          </PrivateRoute>
        }
      />
      <Route
        path="/view-chatbots"
        element={
          <PrivateRoute>
            <ViewChatbots />
          </PrivateRoute>
        }
      />
      <Route
        path="/modify-chatbot/:id"
        element={
          <PrivateRoute>
            <ModifyChatbot />
          </PrivateRoute>
        }
      />
      <Route
        path="/chat/:id"
        element={
          <PrivateRoute>
            <ChatWithChatbot />
          </PrivateRoute>
        }
      />
    </Routes>
    
  );
}

export default App;

