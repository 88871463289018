

// src/components/HomePage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function HomePage() {
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem('user_id');
    if (!userId) {
      // If the user is not logged in, redirect to the login page
      navigate('/login');
    }
  }, [navigate]);


    
    return (
      <div
        className="h-screen flex flex-col items-center justify-center text-center"
        style={{ backgroundColor: '#ffe5f6' }}
      >
        <h1 className="text-black text-4xl font-bold mb-20 mt-6">Khud Ko Clone Kro</h1>

        <div className="absolute top-0 left-0 z-20 ml-2 flex items-center"> {/* Positioned to the far left of the screen */}
        <img src="https://storage.googleapis.com/rgw-public/logo-02.png" alt="Company Logo" className="h-20 mr-4" /> {/* Company logo */}
      </div>

      <div className="grid grid-cols-2 gap-6">
  <button
    className="bg-[#D9008D] text-white py-2 px-6 rounded-lg hover:bg-white hover:text-[#D9008D] border border-[#D9008D] shadow-lg transition duration-300"
    onClick={() => navigate('/create-chatbot')}
  >
    Naya Clone Banao
  </button>
  <button
    className="bg-[#D9008D] text-white py-2 px-6 rounded-lg hover:bg-white hover:text-[#D9008D] border border-[#D9008D] shadow-lg transition duration-300"
    onClick={() => navigate('/view-chatbots')}
  >
    Sabhi Clones Dekho
  </button>
</div>

      </div>
    );
    
    
}

export default HomePage;
