// src/Components/ChatbotList.js

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../config';

function ViewChatbots() {
  const [chatbots, setChatbots] = useState([]);
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const navigate = useNavigate(); // For navigating to the chat or modify interfaces

  useEffect(() => {
    const fetchChatbots = async () => {
      const email = localStorage.getItem('email'); // Get email from localStorage

      if (!email) {
        console.error('No email found in localStorage');
        return;
      }

      try {
        // Fetch chatbots only for the logged-in user using the email
        const response = await fetch(`${API_BASE_URL}/chatbots?email=${email}`);
        const data = await response.json();

        // If no chatbots found for the user, handle the response
        if (response.status === 404) {
          console.error('No chatbots found for this user');
          setChatbots([]); // Clear the chatbots array if no chatbots found
        } else {
          setChatbots(data); // Set chatbots state with the fetched data
        }

      } catch (error) {
        console.error('Error fetching chatbots:', error);
      }
    };

    fetchChatbots();
  }, []);

  // Filter chatbots based on search term
  const filteredChatbots = chatbots.filter((chatbot) =>
    chatbot.chatbot_name?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const MAX_TITLE_LENGTH = 30;

  return (
    <div className="h-screen bg-[#ffe5f6] flex flex-col items-center justify-center text-center">
      <h1 className="text-black text-4xl font-bold mb-8">Upalabdh Clone</h1>

      <div className="absolute top-0 left-0 z-20 ml-2 flex items-center">
        <img
          src="https://storage.googleapis.com/rgw-public/logo-02.png"
          alt="Company Logo"
          className="h-20 mr-4"
        />
      </div>

      {/* Search Box */}
      <input
        type="text"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        placeholder="Clone Ke Nam search karo"
        className="mb-8 w-full max-w-lg p-2 rounded-lg bg-pink-50 text-black border border-[#A5E5C6] focus:outline-none focus:border-pink-800 placeholder:text-sm hover:bg-pink-100 hover:border-pink-600"
      />

      <div className="w-full max-w-3xl h-[40rem] overflow-y-auto text-black space-y-4 border border-[#A5E5C6] p-6 rounded-lg bg-pink-50">
        <ul>
          {filteredChatbots.map((chatbot) => {
            const chatbotTitle = chatbot.chatbot_title || '';
            const truncatedTitle =
              chatbotTitle.length > MAX_TITLE_LENGTH
                ? chatbotTitle.slice(0, MAX_TITLE_LENGTH) + '...'
                : chatbotTitle;

            return (
              <li
                key={chatbot.id}
                className="border border-[#A5E5C6] p-4 rounded-lg hover:bg-pink-100 hover:border-pink-600 cursor-pointer mb-4"
              >
                <div className="flex flex-col md:flex-row justify-between items-center">
                  <div className="text-center md:text-left break-words whitespace-normal">
                    {/* Ensures long text wraps onto the next line */}
                    <span className="text-lg font-semibold text-black">
                      {truncatedTitle}
                    </span>
                    <span className="text-sm text-gray-600 ml-2">
                      (User: {chatbot.user_name})
                    </span>
                  </div>
                  <div className="space-x-4 mt-4 md:mt-0">
                    <button
                      className="bg-[#D9008D] text-white py-2 px-6 rounded-lg hover:bg-white hover:text-[#D9008D] border border-[#D9008D] shadow-lg transition duration-300"
                      onClick={() => navigate(`/chat/${chatbot.id}`)}
                    >
                      Chat
                    </button>
                    <button
                      className="bg-[#D9008D] text-white py-2 px-6 rounded-lg hover:bg-white hover:text-[#D9008D] border border-[#D9008D] shadow-lg transition duration-300"
                      onClick={() => navigate(`/modify-chatbot/${chatbot.id}`)}
                    >
                      Modify
                    </button>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}

export default ViewChatbots;
